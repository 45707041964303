import React, { useEffect, useState, Fragment } from 'react';
import MUIDataTable from 'mui-datatables';
import Buffering from '../Buffering/Buffering';
import { useData } from '../../services/data_service';
import { useStateHooks } from '../../services/state_hook_service';
import {useHistory} from 'react-router-dom';
import './ResultsTable.scss'
import {BsQuestion} from 'react-icons/bs'

export default function ResultsTable(){
    const DataSvc = useData();
    const StateHookSvc = useStateHooks();
    const history = useHistory();
    const [ noResultsFound, setNoResultsFound] = useState(false);
    const [theDate, setTheDate] = useState(null);
    let tableBodyHeightVal = (StateHookSvc.chartDivHeight ) + "px";
    // const data = [
    //     {
    //         // 'Land Cover': 'Rangeland',
    //         'Measured Element': 'Litter Cover',
    //         'Group': 'Rangeland',
    //         'Control/Pre-Treatment': 'Controlled',
    //         'Measured Value': 'Feed and Forage balance',
    //         'Control/Pre-treatment Value': '531',
    //         'Units': 'Feet',
    //         'Relative Percent Change': '5%',
    //         'Land Cover': 'Rangeland',
    //     },
    //     {
    //         // 'Land Cover': 'Pastureland',
    //         'Measured Element': 'Standing Biomass - Total',
    //         'Control/Pre-Treatment': 'Pre-treatment',
    //         'Group': 'Cropland',
    //         'Measured Value': 'Perscribed Grazing',
    //         'Control/Pre-treatment Value': '531',
    //         'Units': 'Acres',
    //         'Relative Percent Change': '30%',
    //         'Land Cover': 'Pastureland',
    //     }
    // ];



    const options = {
        print: true,
        download: true,
        tableBodyHeight: tableBodyHeightVal,
        selectableRowsHideCheckboxes: true,
        downloadOptions: {filename: `COREdownload_${theDate}.csv`, separator: ','},
        rowsPerPageOptions: [],
        draggableColumns : {
            enabled: true
        }
    };

    const columns = [
        // {name: 'Land Use', 
        // options: {
        //     hint: 'Hover Text Will Go In Here'
        // }}, 
        {name: 'Group', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }},
        {name: 'Units', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }},
        {name: 'Control or Pre-Treatment', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }},
        {name: 'Measured Value', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }}, 
        {name: 'Control/Pre-Treatment Value', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }},
        {name: 'Absolute Difference', 
            options: {
            hint: 'Hover Text Will Go In Here',
        }},  
        {name: 'Relative % Difference', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }},
        {name: 'Table/Figure', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }}, 
        {name: 'Citation Link', 
            options: {
            hint: 'Hover Text Will Go In Here',
            customBodyRender: (val) => {
                return (
                    <a href={val}>{val}</a>
                )
            },
        }},
        {name: 'Notes', 
            options: {
            hint: 'Hover Text Will Go In Here'
        }}, 
    ];    

    useEffect(() => {
        // console.log('params', StateHookSvc.selectedLandUse, StateHookSvc.violinPlotTitle, StateHookSvc.selectedResourceComponent, StateHookSvc.selectedOutcome)
        let date = new Date().toLocaleDateString();
        setTheDate(date);
        // DataSvc.fetchSecondViolin();
        // let target = StateHookSvc.finalTableCells;
        // for(let i = 0; i < target.length; i++){
        //     target[i]['Citation Link'] = <a href={target[i]['Citation Link']} target="_blank">{target[i]['Citation Link']}</a>
        // }
        // console.log('target', target);
        // setLocalTableData(target)
        if(StateHookSvc.totalObsCount == 0 && StateHookSvc.totalStudyCount == 0){
            //bar chart will be reset
            return StateHookSvc.setFinalTableCells([]);
        }else{
            return DataSvc.filterMeasuredElementArray(StateHookSvc.selectedOutcome, history);
        }
    }, [
        StateHookSvc.selectedLandUse,
        StateHookSvc.violinPlotTitle,
        StateHookSvc.selectedResourceComponent,
        StateHookSvc.selectedOutcome,
        StateHookSvc.totalObsCount,
        StateHookSvc.totalStudyCount
    ]);

    function countsCheck(){
        if(StateHookSvc.totalObsCount == 0 && StateHookSvc.totalStudyCount == 0){
            console.log('empty')
            StateHookSvc.setFinalTableCells([]);
            return StateHookSvc.finalTableCells;
        }else{
            console.log('not empty')
            return StateHookSvc.finalTableCells
        }
    }


    return(
        <>
            {/* <span><BsQuestion/></span> */}
            <MUIDataTable
                title={StateHookSvc.selectedOutcome}
                data={StateHookSvc.finalTableCells}
                columns={columns}
                options={options}
            />
        </>
    )
}